/* Default Light Theme Styles */
.ag-theme-alpine {
  --ag-header-foreground-color: black;
  --ag-header-background-color: #f9fafb;
  --ag-selected-row-background-color: #d7ede0;
  --ag-row-hover-color: #dfdfdf;

  /* --ag-borders: none !important; */
}

.ag-right-aligned-cell {
  font-variant-numeric: tabular-nums;
  -moz-font-feature-settings: 'tnum';
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum';
}

.ag-theme-alpine .ag-header-group-cell,
.ag-theme-alpine .ag-header-cell {
  font-weight: normal;
  font-size: 14px;
}

.ag-header {
  background: #f9fafb !important;
  border-bottom: 1px solid #dde2eb;
  margin-bottom: 0;
  padding-left: 0;
}

.ag-row-level-1 .ag-header {
  background: #f9fafb !important;
}

.ag-row-level-1 .ag-row-odd,
.ag-row-odd {
  background: #fafafa !important;
  /* border: none !important; */
}

.ag-row-level-1 .ag-row-even {
  background: #f9fafb !important;
  /* border: none !important; */
}

.ag-cell {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  border-inline: 1px solid #dde2eb;
}

.ag-cell:first-child {
  border: none;
}

.ag-header-cell {
  padding-left: 12px;
}

.ag-popup-editor,
.ag-right-select {
  height: 240px;
}

/* Dark Theme Styles */
.chakra-ui-dark .ag-theme-alpine {
  --ag-header-foreground-color: rgb(204, 245, 172);
  --ag-header-background-color: rgb(209, 64, 129);
  --ag-selected-row-background-color: #34495e;
  --ag-row-hover-color: #3e5060;
  --ag-foreground-color: white;
  --ag-background-color: #34495e;
}

.chakra-ui-dark .ag-header-cell {
  background: #2c3e50 !important;
  color: white;
}

.chakra-ui-dark .ag-theme-alpine .ag-header-group-cell,
.chakra-ui-dark .ag-theme-alpine .ag-header-cell {
  color: white;
  background: #2c3e50 !important;
}

.chakra-ui-dark .ag-header {
  background: #2c3e50 !important;
  border-bottom: 1px solid #3e5060;
  color: white;
}

.chakra-ui-dark .ag-row-level-1 .ag-header {
  background: #2c3e50 !important;
}

.chakra-ui-dark .ag-row-level-1 .ag-row-odd,
.chakra-ui-dark .ag-row-odd {
  background: #3e5060 !important;
}

.chakra-ui-dark .ag-row-level-1 .ag-row-even,
.chakra-ui-dark .ag-row-even {
  background: #34495e !important;
}

.chakra-ui-dark .ag-menu {
  background-color: #326393 !important; /* light purple */
}

.chakra-ui-dark .ag-menu-header {
  background-color: #34495e !important; /* dark purple */
}

.chakra-ui-dark .ag-menu-header .ag-icon {
  color: white;
}
